import React, { useState } from "react";
import { Modal, Form, Input } from "antd";
import { IoMdCloseCircle } from "react-icons/io";
const ViewDescription = ({ children, initialValues }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  return (
    <>
      <span
        className="pointer primary-color  regular-font"
        onClick={() => showModal()}
      >
        {children}
      </span>
      <Modal
        className="form-modal"
        title="View Modal"
        open={isModalOpen}
        closeIcon={<IoMdCloseCircle size="20" />}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">
          <Form
            layout="vertical"
            initialValues={initialValues}
            name="Form"
            form={form}
          >
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input.TextArea readOnly rows={8} placeholder="Enter your description" />
            </Form.Item>
          </Form>
        </div>
        <hr />
      </Modal>
    </>
  );
};
export default ViewDescription;
