import React from "react";
import { Form, Input, Button } from "antd";
import { useParams } from "react-router-dom";
const SetNewPasswordForm = ({ setNewSpin }) => {
  const params = useParams();

  const onFinished = (values) => {
    if (params && params.query) {
      values.forgetPasswordAuthToken = params.query;
    }
  };
  return (
    <Form
      className="auth-form-width"
      layout="vertical"
      onFinish={onFinished}
      name="SetNewPasswordForm"
    >
      <Form.Item
        name="password"
        className="mb-3"
        label={
          <label
            style={{
              color: "#515151",
              fontSize: "18px",
              fontFamily: "Lato Regular",
            }}
          >
            New Password
          </label>
        }
        rules={[
          {
            required: true,
            message: "Please enter your password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password
          className="auth-input"
          placeholder="Enter your Password"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassowrd"
        label={
          <label
            style={{
              color: "#515151",
              fontSize: "18px",
              fontFamily: "Lato Regular",
            }}
          >
            Confirm Password
          </label>
        }
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your Password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password
          className="auth-input"
          placeholder="Enter your confirm password"
        />
      </Form.Item>

      <Button
        loading={setNewSpin}
        disabled={setNewSpin}
        htmlType="submit"
        className="auth-button"
      >
        Update Password
      </Button>
    </Form>
  );
};

export default SetNewPasswordForm;
