import { Image, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { GrUserAdmin } from "react-icons/gr";

import React from "react";
import MenuItems from "./MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/error.png";
import admin from "../../../assets/images/admin.png";
import { ContextProvider } from "../../../context/ContextProvider";
function Sidebar({ collapsed }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { config } = ContextProvider();
  const { user } = config;
  const selectedKeys = pathname.substr(1);
  let keyArray = selectedKeys.split("/");
  let defaultOpenKeys = [keyArray[0]];
  if (selectedKeys.split("/").length === 3) {
    defaultOpenKeys = [keyArray[0], keyArray[1]];
  }
  return (
    <Sider
      className={!collapsed ? "sidebarWidth" : ""}
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        backgroundColor: "#EDF2F9",
        boxShadow: "0px 13px 16px #00000029",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center pt-4 pb-4"
        style={{ height: "65px", backgroundColor: "#3989f0", color: "#FFFFFF" }}
      >
        <img style={{ height: "50px", width: "50px" }} src={logo} alt="" />

        {!collapsed && (
          <p className="font-catellos-demo font-30 ps-2"> Logs </p>
        )}
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center gap-3 my-3 pt-3 pb-3">
        <Image
          src={user && user.profileImageUrl ? user.profileImageUrl : admin}
          style={{ width: collapsed ? "60px" : "120px" }}
          className="rounded-circle"
        />
        <h3
          style={{ color: "#3989f0" }}
          className={`${collapsed ? "font-14" : "font-24"} font-sans-bold`}
        >
          {user && user.fullName ? user.fullName : "Admin"}
        </h3>
      </div>
      <Menu
        style={{
          backgroundColor: "#EDF2F9",
          color: "#515151",
          fontFamily: "Lato Bold",
          fontSize: "18px",
        }}
        theme="dark"
        mode="inline"
        className="menu"
        defaultSelectedKeys={["1"]}
        items={MenuItems}
        onClick={(e) => navigate(e.key)}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={["/" + selectedKeys]}
      />
    </Sider>
  );
}

export default Sidebar;
