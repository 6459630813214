import React from "react";
import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { ContextProvider } from "../../context/ContextProvider";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../utils/commonFunctions";
const { confirm } = Modal;
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const ConfirmationModal = ({
  title,
  content,
  buttonName,
  selectedItem,
  oKText,
  recordName,
  apiName,
  cancelText,
}) => {
  const { config, setConfig } = ContextProvider();

  const showDeleteConfirm = () => {
    confirm({
      title: title,
      icon: (
        <QuestionCircleOutlined
          style={{
            color: "#3989f0",
          }}
        />
      ),
      content: content,
      okText: oKText || "Delete",
      okType: "primary",
      cancelText: cancelText || "Cancel",
      confirmLoading: true,

      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            let Authorization = null;
            let user = JSON.parse(localStorage.getItem("userInfo"));
            if (user && user.token) {
              Authorization = user.token;
            }
            let headers = {
              "Content-Type": "application/json",
              Authorization: Authorization,
              APIType: "web",
            };
            axios({
              url: `${ROOT_URL}/${apiName}`,
              method: "put",
              headers: headers,
              data: selectedItem,
            })
              .then(function (response) {
                if (response && response.data && response.data.Record) {
                  if (response.data.status === "SUCCESS") {
                    if (config && config[recordName]) {
                      let tableData = config[recordName];
                      let newArray = [];
                      let updateObj = {};
                      tableData.map((Item) => {
                        if (
                          Item._id === response.data.Record._id
                        ) {
                          newArray.push(response.data.Record);
                        } else {
                          newArray.push(Item);
                        }
                        return Item;
                      });
                      // updateObj = {
                      //   ...response.data.Record,
                      //   tableData: newArray,
                      // };
                      setConfig(recordName, newArray);
                      showSuccessNotification(response);
                    } else {
                      setConfig(recordName, null);
                    }
                  }
                }
                resolve();
              })
              .catch(function (error) {
                showErrorNotification(error);
                reject();
              });
          });
        } catch {}
      },
      onCancel() {},
    });
  };

  return (
    <span
      className="cursor-pointer color-515151 font-16 regular-font actionBTn"
      style={{ cursor: "pointer" }}
      onClick={showDeleteConfirm}
    >
      {buttonName}
    </span>
  );
};

export default ConfirmationModal;
