import React from "react";
import { Button, Form, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ContextProvider } from "../../context/ContextProvider";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const Filter = ({
  selectKey = "value",
  searchKey = "name",
  label,
  value,
  Options,
  SearchBox,
  SelectBox,
  SelectBoxApiName,
  SelectBoxApiRecordName,
  SelectBoxWidth,
  SearchBoxWidth,
  searchPlaceholder,
  selectPlaceholder,
  apiName,
  recordName,
  rangePicker,
}) => {
  const { DataGetAction, config } = ContextProvider();
  const fetchListData = () => {
    if (SelectBoxApiName) {
      DataGetAction(SelectBoxApiName, { query: "all" }, SelectBoxApiRecordName);
    }
  };
  const handleChange = (e) => {
    if (e.target.value === "") {
      DataGetAction(apiName, { query: "all" }, recordName);
    }
  };
  const onFinish = (values) => {
    if (values) {
      DataGetAction(apiName, values, recordName);
    } else {
      DataGetAction(apiName, { query: "all" }, recordName);
    }
  };
  React.useEffect(() => {
    fetchListData();
  }, [SelectBoxApiName]);
  return (
    <Form
      onFinish={onFinish}
      className="d-flex flex-wrap align-items-center gap-2"
    >
      {SearchBox && (
        <Form.Item name="search" className="mb-0">
          <Input
            style={{ width: SearchBoxWidth }}
            placeholder={searchPlaceholder}
            allowClear
            onChange={handleChange}
          />
        </Form.Item>
      )}
      {SelectBox && (
        <Form.Item
          name={SelectBoxApiName ? "projectKey" : "select"}
          className="mb-0"
        >
          <Select
            style={{ width: SelectBoxWidth }}
            placeholder={selectPlaceholder}
            allowClear
          >
            {SelectBoxApiName
              ? config &&
                config[SelectBoxApiRecordName] &&
                config[SelectBoxApiRecordName].map((item) => (
                  <Select.Option value={item.projectKey}>
                    {item.projectName}
                  </Select.Option>
                ))
              : Options &&
                Options.map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>
      )}
      {rangePicker && (
        <Form.Item className="mb-0" name="rangePicker">
          <RangePicker />
        </Form.Item>
      )}
      <Form.Item className="mb-0">
        <Button type="primary" htmlType="submit" className="px-2">
          <div className="d-flex gap-2">
            <SearchOutlined className="bold-font" />
            <span>Search</span>
          </div>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Filter;
