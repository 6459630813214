import { message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationCard from "../AuthCard";
import SetNewPasswordForm from "./components/SetNewPasswordForm";
const Index = () => {

  return (
    <AuthenticationCard
      title="Set New Password"
      content="Set your account's new password"
    >
      <SetNewPasswordForm />
    </AuthenticationCard>
  );
};

export default Index;
