import React, { useEffect } from "react";
import { Table } from "antd";
import { ContextProvider } from "../../context/ContextProvider";

const TableList = ({
  apiName,
  recordName,
  columns,
  withTableData,
  pagination = true,
}) => {
  const { config, DataGetAction } = ContextProvider();
  const [pageNum, setPageNum] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const getNextPageData = (page, pageSize) => {
    setPageNum(page);
  };
  const getSizePageChangeData = (current, size) => {
    setLimit(size);
  };
  const callApi = () => {
    DataGetAction(
      apiName,
      { query: "all", pageNumber: pageNum, limit: limit },
      recordName
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callApi, [pageNum, limit]);

  const dataSource = () => {
    if (withTableData) {
      return config && config[recordName] && config[recordName].tableData;
    } else {
      return config && config[recordName] && config[recordName];
    }
  };
  return (
    <Table
      className="table-responsive"
      columns={columns}
      loading={config && config[`${recordName}_spin`]}
      dataSource={
        config && config[`${recordName}_spin`] ? [] : dataSource() || []
      }
      pagination={
        !pagination
          ? false
          : {
              total: config && config[recordName] && config[recordName].total,
              current: pageNum,
              onChange: getNextPageData,
              onShowSizeChange: getSizePageChangeData,
            }
      }
    />
  );
};
export default TableList;
