import React from "react";

import { Route, Routes } from "react-router-dom";
import Login from "../screens/Auth/Login";
import ForgetPassword from "../screens/Auth/ForgetPassword";
import SetNewPassword from "../screens/Auth/SetNewPassword";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/setNewPassword" element={<SetNewPassword />} />
    </Routes>
  );
};

export default AuthRoutes;
