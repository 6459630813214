import React from "react";
import Action from "../../components/Action";
import Table from "../../components/Table";
// import Title from "../../components/Title";
import Widget from "../../components/Widget";
import TableHeader from "./components/TableHeader";
import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment";
import EditUser from "./components/EditUser";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import ResetPassword from "../../components/Modal/ResetPassword";
import { renderStatus } from "../../utils/commonFunctions";
import ViewProject from "./components/ViewProject";

function Index() {
  const renderActions = (record) => {
    return (
      <div className="d-flex flex-column">
        {record.status === "block" && (
          <ConfirmationModal
            buttonName="Active"
            title="Are you sure you want to active this record?"
            selectedItem={{ _id: record._id, status: "active" }}
            apiName="updateUserStatus"
            recordName="userList"
            oKText="Active"
            cancelText="Cancel"
          />
        )}
        {record.status === "active" && (
          <ConfirmationModal
            buttonName="Block"
            title="Are you sure you want to block this record?"
            selectedItem={{ _id: record._id, status: "block" }}
            apiName="updateUserStatus"
            recordName="userList"
            oKText="Block"
            cancelText="Cancel"
          />
        )}
        {record.status === "active" && <EditUser record={record} />}
        <DeleteModal
          buttonName="Delete"
          title="Are you sure you want to delete this record?"
          selectedItem={record}
          apiName="deleteUser"
          recordName="userList"
          oKText="Delete"
          cancelText="Cancel"
        />
        {record.status === "active" && (
          <ResetPassword
            apiName="resetPassword"
            record={record}
            recordName="userList"
          />
        )}
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.userId}</span>,
      key: "userId",
      width: "60px",
    },
    {
      title: "Fullname",
      render: (record) => (
        <span className="text-capitalize">{record.fullName}</span>
      ),
      key: "fullName",
      width: "120px",
    },
    {
      title: "Email",
      render: (record) => (
        <span className="text-capitalize">{record.email}</span>
      ),
      key: "email",
      width: "120px",
    },
    {
      title: "Projects",
      render: (record) => (
        <ViewProject initialValues={record}>view</ViewProject>
      ),
      key: "description",
      width: "120px",
    },
    {
      title: "CreatedAt",
      render: (record) => (
        <span>{moment(record.createdAt).format("DD-MM-YYYY")}</span>
      ),
      key: "createdAt",
      width: "160px",
    },
    {
      title: "Status",
      render: (record) => (
        <span className="text-capitalize">{renderStatus(record.status)}</span>
      ),
      key: "status",
      width: "120px",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "0px",
    },
  ];

  return (
    <>
    <Widget Header={<TableHeader />}>
      <Table columns={columns} apiName="getUsersList" recordName="userList" />
    </Widget>
    </>
  );
}

export default Index;
