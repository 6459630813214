import React from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";

function ForgetPasswordForm() {
  const navigate = useNavigate();
  const onFinished = (values) => {
    if (values.email) {
      values.email = values.email.toLowerCase();
    }
  };
  return (
    <Form
      onFinish={onFinished}
      name="ForgetForm"
      className=""
      layout="vertical"
    >
      <Form.Item
        name="email"
        label={
          <label
            style={{
              color: "#515151",
              fontSize: "18px",
              fontFamily: "Lato Regular",
            }}
          >
            Email
          </label>
        }
        className="font-sans-regular font-18"
        rules={[
          {
            type: "email",
            message: "Please enter valid Email!",
          },
          {
            required: true,
            message: "Please enter your Email!",
          },
        ]}
      >
        <Input className="auth-input" placeholder="Enter your email" />
      </Form.Item>
      <Button htmlType="submit" className="auth-button">
        Send Email
      </Button>
      <div className="d-flex justify-content-end mt-2">
        <u
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
          className="font-16 font-sans-bold text-color-000000"
        >
          Back to login
        </u>
      </div>
    </Form>
  );
}

export default ForgetPasswordForm;
