import React from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import UserForm from "./UserForm";
import { Form } from "antd";

const EditUser = ({ record }) => {
  const [form] = Form.useForm();
console.log("=== record =====",record)
  return (
    <EditFormModal
      apiName="updateUser"
      modalTitle="Edit User"
      recordName="userList"
      initialValues={record}
      Form={Form}
      form={form}
    >
      <UserForm
        Form={Form}
        form={form}
        formType="edit"
      />
    </EditFormModal>
  );
};

export default EditUser;
