import { Avatar, Popover } from "antd";
import React, { useState } from "react";
import admin from "../../../assets/images/admin.png";
import { GrFormDown } from "react-icons/gr";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import { IoLogOutSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ContextProvider } from "../../../context/ContextProvider";
import { logout } from "../../../utils/commonFunctions";
const UserInfo = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { config, setConfig } = ContextProvider();
  const { user } = config;

  const content = (
    <div
      onClick={() => setOpen(false)}
      className="d-flex flex-column"
      style={{ cursor: "pointer" }}
    >
      <span className="actionBTn" onClick={() => navigate("/setting")}>
        <HiAdjustmentsHorizontal size="20px" /> Account Setting
      </span>
      <span   className="actionBTn" onClick={() => logout(setConfig, navigate)}>
        <IoLogOutSharp size="20px" /> Logout
      </span>
    </div>
  );
  return (
    <div className="me-4  px-3" style={{ cursor: "pointer" }}>
      <Avatar
        src={user && user.profileImageUrl ? user.profileImageUrl : admin}
        style={{ boxShadow: "0px 3px 6px #00000029" }}
        className="me-2"
      />
      <Popover
        open={open}
        onOpenChange={(e) => setOpen(e)}
        content={content}
        placement="bottomLeft"
        trigger="click"
        className="pointer text-capitalize"
      >
        {user && user.fullName ? user.fullName : "Admin"} <GrFormDown />
      </Popover>
    </div>
  );
};

export default UserInfo;
