import React from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import ProjectForm from "./ProjectForm";
import { Form } from "antd";
const AddProject = () => {
  const [form] = Form.useForm();
  return (
    <AddFormModal
      apiName="addProject"
      recordName="projectList"
      modalTitle="Add Project"
      Form={Form}
      form={form}
    >
      <ProjectForm Form={Form} />
    </AddFormModal>
  );
};

export default AddProject;
