import React from "react";
import AuthenticationCard from "../AuthCard";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import LoginForm from "./components/LoginForm";
import { ContextProvider } from "../../../context/ContextProvider";
import { onLoginSuccess } from "../../../utils/commonFunctions";
const Index = () => {
  const navigation = useNavigate();
  const { setConfig, config } = ContextProvider();

  if (config && config.loginRecord_success) {
    onLoginSuccess(setConfig, navigation, config.loginRecord, "admin","dashboard");
    setConfig("loginRecord_success", false);
  }

  return (
    <AuthenticationCard
      title="Account Login"
      content="Welcome, Login to your account to continue"
    >
      <LoginForm />
    </AuthenticationCard>
  );
};

export default Index;
