
import React from "react";
import Table from "../../components/Table";
import EditProject from "./components/EditProject";
import DeleteModal from "../../components/Modal/DeleteModal";
import Action from "../../components/Action";
import Widget from "../../components/Widget";
import TableHeader from "./components/TableHeader";
import moment from "moment/moment";
function Index() {
  const renderActions = (record) => {
    return (
      <div className="d-flex flex-column">
        <EditProject record={record} />
        <DeleteModal
          buttonName="Delete"
          title="Are you sure you want to delete this record?"
          selectedItem={record}
          apiName="deleteProject"
          recordName="projectList"
          oKText="Delete"
          cancelText="Cancel"
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.projectId}</span>,
      key: "projectId",
      width: "60px",
    },
    {
      title: "Project Name",
      render: (record) => (
        <span className="text-capitalize">{record.projectName}</span>
      ),
      key: "projectName",
      width: "120px",
    },
    {
      title: "Project Key",
      render: (record) => <span>{record.projectKey}</span>,
      key: "projectKey",
      width: "120px",
    },
    {
      title: "CreatedAt",

      render: (record) => (
        <span>{moment(record.createdAt).format("DD-MM-YYYY")}</span>
      ),
      key: "createdAt",
      width: "160px",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "0px",
    },
  ];
  // const data = [
  //   {
  //     key: "1",
  //     name: "John Brown",
  //     age: 32,
  //     address: "New York No. 1 Lake Park",
  //     tags: ["nice", "developer"],
  //   },
  //   {
  //     key: "2",
  //     name: "Jim Green",
  //     age: 42,
  //     address: "London No. 1 Lake Park",
  //     tags: ["loser"],
  //   },
  //   {
  //     key: "3",
  //     name: "Joe Black",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park",
  //     tags: ["cool", "teacher"],
  //   },
  // ];
  return (
    <Widget Header={<TableHeader />}>
      <Table
        columns={columns}
        apiName="getProjectList"
        recordName="projectList"
      /> 
    </Widget>
  );
}

export default Index;
