import React from "react";
import AuthenticationCard from "../AuthCard";
import ForgetPasswordForm from "./components/ForgetPasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
function Index() {
  const navigate = useNavigate();

  return (
    <AuthenticationCard
      title="Forgot Password"
      content="Enter your registered email address for recovery email"
    >
      <ForgetPasswordForm />
    </AuthenticationCard>
  );
}

export default Index;
