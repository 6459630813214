import { RiDashboardFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { AiTwotoneDatabase, AiFillSetting } from "react-icons/ai";

import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { GrProjects } from "react-icons/gr";
const MenuItems = [
  {
    key: "/dashboard",
    icon: <RiDashboardFill size={20} />,
    label: "Dashboard",
  },
  {
    key: "/user",
    icon: <FaUserAlt size={18} />,
    label: "Users",
  },
  {
    key: "/projects",
    icon: <GrProjects size={16} />,
    label: "Projects",
  },
  {
    key: "/logs",
    icon: <AiTwotoneDatabase size={20} />,
    label: "Logs",
  },
  {
    key: "/setting",
    icon: <AiFillSetting size={20} />,
    label: "Setting",
  },
];
export default MenuItems;
