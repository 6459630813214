import React from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ContextProvider } from "../../../../context/ContextProvider";
const LoginForm = ({ loginSpin }) => {
  const navigate = useNavigate();
  const { config, DataRequestAction } = ContextProvider();
  const onFinish = (values) => {
    DataRequestAction("post", "login", values, "loginRecord");
  };
  return (
    <Form
      name="LoginForm"
      className="auth-form-width"
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        label={
          <label
            style={{
              color: "#515151",
              fontSize: "18px",
              fontFamily: "Lato Regular",
            }}
          >
            Email
          </label>
        }
        className="font-sans-regular font-18"
        rules={[
          {
            type: "email",
            message: "Please enter valid Email!",
          },
          {
            required: true,
            message: "Please enter your Email!",
          },
        ]}
      >
        <Input className="auth-input" placeholder="Enter your email" />
      </Form.Item>
      <Form.Item
        name="password"
        className="mb-3"
        label={
          <label
            style={{
              color: "#515151",
              fontSize: "18px",
              fontFamily: "Lato Regular",
            }}
          >
            Password
          </label>
        }
        rules={[
          {
            required: true,
            message: "Please enter your password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password
          className="auth-input"
          placeholder="Enter your Password"
        />
      </Form.Item>
      <Form.Item className="mb-3 d-flex justify-content-end">
        <u
          onClick={() => navigate("/forgetPassword")}
          style={{ cursor: "pointer" }}
          className=" forgetPassword font-16 font-sans-bold"
        >
          Forgot your password?
        </u>
      </Form.Item>

      <Button
        loading={config && config.loginRecord_spin}
        disabled={config && config.loginRecord_spin}
        className="auth-button"
        htmlType="submit"
      >
        Login
      </Button>
    </Form>
  );
};

export default LoginForm;
