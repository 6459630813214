import React, { useState } from "react";
import { Modal, Form, Input } from "antd";

import { IoMdCloseCircle } from "react-icons/io";
const ViewProject = ({ children, initialValues }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  return (
    <>
      <span
        className=" primary-color  regular-font text-primary text-capitaize"
        style={{ cursor: "pointer" }}
        onClick={() => showModal()}
      >
        {children}
      </span>
      <Modal
        className="form-modal"
        title="Assigned Projects"
        open={isModalOpen}
        closeIcon={<IoMdCloseCircle size="20" />}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">
          <ul className="list-group list-group-flush list-group-numbered">
            {initialValues.projectInfo.map((item, index) => (
              <li
                key={index}
                className="list-group-item text-capitalize font-16 font-sans-regular"
              >
                   {item.projectName}
              </li>
            ))}
          </ul>
        </div>
        <hr />
      </Modal>
    </>
  );
};
export default ViewProject;
