import React from "react";
import Filter from "../../../components/Filter";
import Title from "../../../components/Title";
import AddLog from "./AddLog";
const TableHeader = () => {
  return (
    <div className="d-flex align-items-center justify-content-between flex-wrap">
      <Title text="Logs" />
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
        <Filter
          SelectBox={true}
          SelectBoxApiName="getProjectNameList"
          SelectBoxApiRecordName="projectNameList"
          SelectBoxWidth="200px"
          selectPlaceholder="Project Name"
          rangePicker={true}
          apiName="getLogList"
          recordName="logList"
        />
      </div>
    </div>
  );
};

export default TableHeader;
