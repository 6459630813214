import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/fonts/index.css";
import "./styles/custom.css";
import "bootstrap/dist/css/bootstrap.css";
import { variables } from "./styles/variables";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import ContextProvider from "./context/ContextProvider.jsx";
import "react-phone-input-2/lib/style.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: variables.primary,
        },
      }}
    >
      <ContextProvider>
        <App />
      </ContextProvider>
    </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();
