import { Card, Col, Row } from "antd";
import React from "react";
import Logo from "../../../assets/images/error.png";
function Index({ children, title, content }) {
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <Col xxl={12} xl={12}>
        <Row>
          <Col
            xxl={10}
            xl={10}
            lg={10}
            md={10}
            sm={10}
            className="d-flex align-items-center justify-content-center p-5"
            style={{
              backgroundColor: "#2569C3",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              color: "#FFFFFF",
            }}
          >
            <div>
              <Row className="d-flex align-items-center justify-content-center p-3 mb-2">
                <img src={Logo} alt="" style={{ height: "100px" }} />
              </Row>
              <Row className="d-flex align-items-center justify-content-center">
                <p className="font-sans-bold font-22">{content}</p>
              </Row>
            </div>
          </Col>

          <Col
            xxl={14}
            xl={14}
            lg={14}
            md={14}
            sm={14}
            className="d-flex align-items-center"
          >
            <Card
              style={{
                width: "100%",
                height: "100%",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                // padding: "20px 10px",
              }}
            >
              <p
                className="font-sans-bold font-24 mb-4 "
                style={{ color: "#515151" }}
              >
                {title}
              </p>
              {children}
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  );
}

export default Index;
