import React, { useEffect, useState } from "react";
import { Modal, Form, Input } from "antd";
import { ContextProvider } from "../../../context/ContextProvider";
import { IoMdCloseCircle } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
const EditSetting = ({ initialValues, editButton, width }) => {
  const [form] = Form.useForm();
  const { config, setConfig, DataRequestAction } = ContextProvider();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = (values) => {
    if (initialValues && initialValues._id) {
      values._id = initialValues._id;
    }
    DataRequestAction("put", "updateProfile", values, "profileUpdateData");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (config && config.profileUpdateData_success) {
      setIsModalOpen(false);
      let obj = JSON.parse(localStorage.getItem("userInfo"));
      obj["fullName"] = config.profileUpdateData.fullName;
      obj["phoneNumber"] = config.profileUpdateData.phoneNumber;
      localStorage.setItem("userInfo", JSON.stringify(obj));
      setConfig("user", obj);
      setConfig("profileUpdateData_success", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config && config.profileUpdateData_success]);
  return (
    <>
      <span onClick={() => showModal()}>{editButton}</span>
      <Modal
        className="form-modal"
        title="Update Profile"
        open={isModalOpen}
        width={width}
        okText="Save"
        cancelText="Cancel"
        confirmLoading={config && config.profileUpdateData_spin}
        closeIcon={<IoMdCloseCircle size="20" />}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleOk(values);
            })
            .catch((info) => {});
        }}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3">
          <Form
            layout="vertical"
            initialValues={initialValues}
            name="Form"
            form={form}
          >
            <Form.Item
              name="fullName"
              label="Full Name"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <Input className="text-capitalize" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <PhoneInput
                inputStyle={{
                  width: "100%",
                }}
                country={"us"}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber({ phone })}
              />
            </Form.Item>
          </Form>
        </div>
        <hr />
      </Modal>
    </>
  );
};
export default EditSetting;
