import React from "react";
import { Input, Select } from "antd";
import { ContextProvider } from "../../../context/ContextProvider";
const UserForm = ({ Form, formType, form, SelectBoxApiName }) => {
  const { DataGetAction, config } = ContextProvider();
  const fetchListData = () => {
    DataGetAction("getProjectNameList", { query: "all" }, "projectNameList");
  };
  React.useEffect(fetchListData, []);
  return (
    <>
      <Form.Item
        name="fullName"
        label="FullName"
        rules={[{ required: true, message: "Full Name is required" }]}
      >
        <Input
          className="text-capitalize"
          // readOnly
          placeholder="Enter your full name"
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          { required: true, message: "Email is required" },
        ]}
      >
        <Input
          className="text-capitalize"
          type="email"
          readOnly={formType === "edit" ? true : false}
          placeholder="Enter your email"
        />
      </Form.Item>

      <Form.Item name="projects" label="Projects">
        <Select placeholder={`Select Projects`} mode="multiple" allowClear>
          {config &&
            config.projectNameList &&
            config.projectNameList.map((item) => (
              <Select.Option value={item._id}>{item.projectName}</Select.Option>
            ))}
        </Select>
      </Form.Item>

      {formType === "addUser" && (
        <>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Enter your Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default UserForm;
