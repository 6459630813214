import React, { useState } from "react";
import { Modal } from "antd";
import { IoMdCloseCircle } from "react-icons/io";
import ReactJson from "react-json-view";
const ViewResult = ({ children, initialValues }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <span
        className="pointer primary-color  regular-font text-capitalize text-primary"
        style={{ cursor: "pointer" }}
        onClick={() => showModal()}
      >
        {children}
      </span>
      <Modal
        className="form-modal"
        title="View Result"
        open={isModalOpen}
        closeIcon={<IoMdCloseCircle size="20" />}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <hr />
        <div className="px-3 text-capitalize font-sans-regular">
          {initialValues.message ? initialValues.message : ""}
        </div>
        <hr />
        <div className="px-3">
          {initialValues.result ? (
            <ReactJson src={initialValues.result} />
          ) : (
            "No message"
          )}
        </div>

        <hr />
      </Modal>
    </>
  );
};
export default ViewResult;
