import React from "react";
import { Input } from "antd";
const ProjectForm = ({ Form, form }) => {
  return (
    <>
      <Form.Item
        name="projectName"
        label="Project Name"
        rules={[{ required: true, message: "Project name is required" }]}
      >
        <Input
          className="text-capitalize"
          // readOnly
          placeholder="Enter your project name"
        />
      </Form.Item>
    </>
  );
};

export default ProjectForm;
