import { Layout, theme } from "antd";
import { useState } from "react";
import Sidebar from "./components/Sidebar";
import TopHeader from "./components/TopHeader";
const { Header, Content } = Layout;
const Index = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout style={{ height: "100vh" }}>
      <Sidebar collapsed={collapsed} />
      <Layout>
        {/*  top header components */}
        <TopHeader
          Header={Header}
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          colorBgContainer={colorBgContainer}
        />
        <Content
          style={{
            padding: 24,
            minHeight: 280,
            // background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Index;
