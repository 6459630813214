import React from "react";
import DeleteModal from "../../components/Modal/DeleteModal";
import Table from "../../components/Table";
import Widget from "../../components/Widget";
import TableHeader from "./components/TableHeader";
import moment from "moment";
import Action from "../../components/Action";
import ViewResult from "./components/ViewResult";
import { renderStatus } from "../../utils/commonFunctions";
import ViewDescription from "./components/ViewDescription";
function Index() {
  const renderActions = (record) => {
    return (
      <div className="d-flex flex-column">
        {/* <EditLog record={record} /> */}
        <DeleteModal
          buttonName="Delete"
          title="Are you sure you want to delete this record?"
          selectedItem={record}
          apiName="deleteLog"
          recordName="LogList"
          oKText="Delete"
          cancelText="Cancel"
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Project Name",
      render: (record) => (
        <span className="text-capitalize text-center">
          {record.projectName || "----"}
        </span>
      ),
      key: "projectName",
      // width: "120px",
    },
    {
      title: "LogType",
      render: (record) => (
        <ViewDescription initialValues={record}>
          {record.logType && record.logType.length > 30
            ? record.logType.slice(0, 30) + "..."
            : record.logType}
        </ViewDescription>
      ),
      key: "logtype",
      // width: "300px",
    },
    {
      title: "Result",
      render: (record) => <ViewResult initialValues={record}>view</ViewResult>,
      key: "description",
      // width: "120px",
    },
    {
      title: "Result Type",
      render: (record) => (
        <span className="text-capitalize">
          {renderStatus(record.resultType)}
        </span>
      ),
      key: "resultType",
      // width: "120px",
    },
    {
      title: "CreatedAt",

      render: (record) => (
        <span>{moment(record.createdAt).format("DD-MM-YYYY")}</span>
      ),
      key: "createdAt",
      // width: "160px",
    },

    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "0px",
    },
  ];
  return (
    <Widget Header={<TableHeader />}>
      <Table
        columns={columns}
        apiName="getLogList"
        recordName="logList"
        withTableData={true}
        pagination={true}
      />
    </Widget>
  );
}

export default Index;
