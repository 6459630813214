import React from "react";
import AddFormModal from "../../../components/Modal/AddFormModal";
import UserForm from "./UserForm";
import { Form } from "antd";
const AddUser = () => {
  const [form] = Form.useForm();
  return (
    <AddFormModal
      apiName="addUser"
      recordName="userList"
      modalTitle="Add user"
      Form={Form}
      form={form}
    >
      <UserForm
        Form={Form}
        formType="addUser"
        SelectBoxApiName="getProjectNameList"
        SelectBoxApiRecordName="projectNameList"
      />
    </AddFormModal>
  );
};

export default AddUser;
